import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function NeuMails() {
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='un-heading text-center'>
                            <h2>neuMails <span>Services</span></h2>
                            <p className='intro-paragraph'>neuMails is a powerful Customer Engagement Platform designed to facilitate the seamless sending of both marketing and transactional emails. With an intuitive dashboard and a robust Email API, neuMails empowers businesses to enhance communication and drive engagement effectively.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className='mt-5'>
                        <h4 className='mb-4'>Key Features of neuMails</h4>
                    </Col>
                    <Col md={4}>
                        <div className='home-box text-center min-ht'>
                            <h5>Overview dashboard</h5>
                            <p>Easily view counters about the number of email lists, campaigns, subscribers, email templates but also recent campaigns details and a detailed recent activity report.</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='home-box text-center min-ht'>
                            <h5>Suppression lists</h5>
                            <p>Suppression lists can be selected per campaign basis so that the campaign does not send to subscribers in the selected suppression lists. This is complementary to the email blacklist.</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='home-box text-center min-ht'>
                            <h5>API Keys</h5>
                            <p>Create API keys to allow external application to connect to your account and run various actions like adding new subscribers, creating campaigns, etc.</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='home-box text-center min-ht'>
                            <h5>Email Lists Tools</h5>
                            <p>Need to split a large list into multiple smaller ones? Or sync existing email lists? Use this section for these type of actions.</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='home-box text-center min-ht'>
                            <h5>Campaign Management</h5>
                            <p>We denounce with righteous indignation & dislike men who are so beguiled to righteous demorlized</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='home-box text-center min-ht'>
                            <h5>Email lists Management</h5>
                            <p>Create & Manage Unlimited email lists, list subscribers, list segments, custom fields, list forms and list pages.</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='home-box text-center min-ht'>
                            <h5>Data Security</h5>
                            <p>All Servers are Hosted in India.</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='home-box text-center min-ht'>
                            <h5>Email Deliverability</h5>
                            <p>We Enahnce your Email deliverability by deploying SPF, DKIM, DMARC.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default NeuMails
