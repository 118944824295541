import React, { useEffect } from 'react';
import logo from '../images/umbrella-logo.png';
import Menu from './Menu';
import { Link } from "react-router-dom";
import { Col, Container, Row } from 'react-bootstrap';

const Header = () => {
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });
    const isSticky = (e) => {
        const header = document.querySelector('#header');
        const scrollTop = window.scrollY;
        scrollTop >= 0 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };
    const Logo = () => {
        return (
            <div className="logo">
                <Link to="/">
                    <img src={ logo } alt="My Logo" className="img-responsive" />
                </Link>
            </div>
        )
    }

    return (
        <header id="header">
	    	<Container>
		        <Row>
		            <Col md='6'>
		                <Logo />
		            </Col>
		            <Col md='6'>
		                <Menu />
		            </Col>
		        </Row>
            </Container>
	    </header>
    )
}

export default Header;