import { faAngleLeft, faAngleRight, faCheck, faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function Microsoft365() {
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='un-heading'>
                            <h2 className='text-center'>Microsoft <span>365</span></h2>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className='un-heading'>
                            <p>Microsoft 365 aims to provide a comprehensive suite of tools that enhances productivity, facilitates collaboration, and ensures that users have access to the latest technology. It includes popular applications like:</p>
                            <ul className='list-unstyled'>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faAngleRight} className='green' />
                                    </span>
                                    <span className='list-text'>Word</span>
                                </li>
                                <li className='list-item'><span className='list-icon'>
                                    <FontAwesomeIcon icon={faAngleRight} className='green' />
                                </span>
                                    <span className='list-text'>Excel</span>
                                </li>
                                <li className='list-item'><span className='list-icon'>
                                    <FontAwesomeIcon icon={faAngleRight} className='green' />
                                </span>
                                    <span className='list-text'>PowerPoint</span>
                                </li>
                                <li className='list-item'><span className='list-icon'>
                                    <FontAwesomeIcon icon={faAngleRight} className='green' />
                                </span>
                                    <span className='list-text'>Outlook</span>
                                </li>
                                <li className='list-item'><span className='list-icon'>
                                    <FontAwesomeIcon icon={faAngleRight} className='green' />
                                </span>
                                    <span className='list-text'>Teams</span>
                                </li>
                                <li className='list-item'><span className='list-icon'>
                                    <FontAwesomeIcon icon={faAngleRight} className='green' />
                                </span>
                                    <span className='list-text'>OneDrive</span>
                                </li>
                                <li className='list-item'><span className='list-icon'>
                                    <FontAwesomeIcon icon={faAngleRight} className='green' />
                                </span>
                                    <span className='list-text'>SharePoint</span>
                                </li>
                                <li className='list-item'><span className='list-icon'>
                                    <FontAwesomeIcon icon={faAngleRight} className='green' />
                                </span>
                                    <span className='list-text'>Exchange</span>
                                </li>
                            </ul>
                            <p>Microsoft 365 offers various other features and services such as advanced security options, regular updates, and access to online versions of the apps. There are different plans available tailored for personal use, businesses, and educational institutions, each with varying features and support levels.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className='mt-4'>
                        <h4 className='mb-4'>Microsoft 365 for Business</h4>
                        <div className=''>
                            <h5>Microsoft 365 Business Basic</h5>
                            <ul className='list-unstyled'>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Microsoft 365 for Up to 300 Employees</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Professional email with your domain</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Access to web and mobile versions of Word, Excel, PowerPoint, and Outlook</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Chat, call, and video conferencing via Microsoft Teams</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>1 TB of cloud storage per employee</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Includes over 10 additional applications such as Microsoft Bookings, Planner, Forms, and more</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Automatic spam and malware filtering to protect your data</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Anytime phone and web support</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Copilot for Microsoft 365 available as an add-on for advanced assistance and productivity enhancements</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={12} className='mt-4'>
                        <div className=''>
                            <h5>Microsoft 365 Business Standard</h5>
                            <ul className='list-unstyled'>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'><b>Everything in Business Basic, plus</b></span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Desktop versions of Word, Excel, PowerPoint, and Outlook</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Host webinars with attendee registration and reporting</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Collaborative workspaces to co-create using Microsoft Loop</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Edit and design videos with Microsoft Clipchamp</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Copilot for Microsoft 365 available as an add-on for advanced assistance and productivity enhancements.</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={12} className='mt-4'>
                        <div className=''>
                            <h5>Microsoft 365 Business Premium</h5>
                            <ul className='list-unstyled'>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'><b>Everything in Business Standard, plus</b></span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Enhanced identity and access management, cyberthreat protection, and device security</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Tools to discover, classify, and protect sensitive information</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Copilot for Microsoft 365 available as an add-on for advanced assistance and productivity enhancements</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={12} className='mt-4'>
                        <div className=''>
                            <h5>Microsoft 365 Apps for business</h5>
                            <ul className='list-unstyled'>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Desktop versions of Word, Excel, PowerPoint, and Outlook</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>1 TB of cloud storage per employee</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Anytime phone and web support</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Copilot for Microsoft 365 available as an add-on for advanced assistance and productivity enhancements</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={12} className='mt-4'>
                        <h4 className='mb-4'>Microsoft 365 for Enterprise</h4>
                        <div className=''>
                            <h5>Microsoft 365 E3</h5>
                            <ul className='list-unstyled'>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Microsoft 365 apps for desktop and mobile</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Windows for Enterprise</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>1 TB of cloud storage</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Core security and identity management capabilities</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Copilot for Microsoft 365 available as an add-on</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={12} className='mt-4'>
                        <div className=''>
                            <h5>Microsoft 365 E5</h5>
                            <ul className='list-unstyled'>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'><b>Everything in Microsoft 365 E3, plus</b></span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Advanced security and compliance capabilities</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Scalable business analytics with Power BI</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Copilot for Microsoft 365 available as an add-on</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={12} className='mt-4'>
                        <div className=''>
                            <h5>Microsoft 365 F3</h5>
                            <ul className='list-unstyled'>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Microsoft 365 apps for web and mobile</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Standard security capabilities</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Centralized hub for collaboration and productivity</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Custom apps to automate tasks and processes</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Copilot for Microsoft 365 available as an add-on</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Microsoft365