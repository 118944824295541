import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import heroImage from '../images/hero-img.png'
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <div className='un-heading text-center'>
              <h2>About <span>Umbrella Networks</span></h2>
              <p className='intro-paragraph'>At Umbrella Networks, we are committed to providing a comprehensive suite of IT solutions designed to meet your diverse business needs. Our offerings include software licenses, innovative solutions, and hardware—all tailored to enhance your technology environment and drive your business forward.</p>
              <Link to="/about" className='read-more'>Read more</Link>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12} className='mt-5'>
            <div className='un-heading text-center'>
              <h2>Services <span>We Offer</span></h2>
            </div>
          </Col>
          <Col md={4}>
            <div className='home-box text-center'>
              <h5>Software Licenses</h5>
              <p>We streamline the complexities of software procurement and license management, ensuring you receive optimal value and compliance while maximizing your technology investments.</p>
              <Link to="/about" className='read-more'>Read more</Link>
            </div>
          </Col>
          <Col md={4}>
            <div className='home-box text-center'>
              <h5>Solutions</h5>
              <p>Our advanced IT solutions are crafted to address your specific challenges and objectives, equipping your organization with the tools needed to excel in today’s competitive landscape.</p>
              <Link to="/about" className='read-more'>Read more</Link>
            </div>
          </Col>
          <Col md={4}>
            <div className='home-box text-center'>
              <h5>Hardware</h5>
              <p>We offer a range of high-quality hardware solutions that complement our software and solutions, ensuring you have all the essential components to build a robust and effective IT infrastructure.</p>
              <Link to="/about" className='read-more'>Read more</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Home;