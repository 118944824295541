import { BrowserRouter } from "react-router-dom";
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <>
    <BrowserRouter>
    <ScrollToTop />
    <Header />
    <Main />
    <Footer />
    </BrowserRouter>
    </>
  );
}

export default App;
