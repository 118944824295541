import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function GoDmarc() {
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='un-heading text-center'>
                            <h2>GoDMARC <span>Services</span></h2>
                            <p className='intro-paragraph'>DMARC (Domain-based Message Authentication, Reporting & Conformance) is an email security protocol designed to prevent email spoofing and phishing. It builds on existing technologies like SPF (Sender Policy Framework) and DKIM (DomainKeys Identified Mail) by adding a policy layer and reporting mechanism. Domain owners publish a DMARC policy in their DNS records, specifying how to handle emails that fail authentication checks—such as quarantining or rejecting them.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className='mt-5'>
                        <h4 className='mb-4'>Key Benefits of DMARC</h4>
                        <div className=''>
                            <ul className='list-unstyled'>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>DMARC secures your domain name and e-mails</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>DMARC allows a business to know who and what is being sent using your domain name</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>It helps you keep a check on your domain-based email activities</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>DMARC helps prevent unauthorized use of your domain-based emails</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>It helps you keep watch on spammers and email frauds</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>DMARC protects your teammates, vendors, and clients/customers from email scams</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>It gradually makes improvement in your business email deliverability rate</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Helps in improving the reputation of your brand name</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>It plays an important role in building and maintaining brand credibility</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>It safeguards your one or more business brands against imitation</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>DMARC easily makes e-mails identifiable – thus helping in identifying if the email sent or
                                        received is legitimate or illegitimate</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={12} className='mt-4'>
                        <h4 className='mb-4'>GoDMARC One of the leading DMARC Service provider Globally</h4>
                        <div className=''>
                            <p>Explore the incredible capabilities of GoDMARC, an advanced cloud-based tool made to simplify your DMARC setup. Tailored precisely to suit business needs, GoDMARC gives you a complete view of your email security with its easy-to-use dashboard.</p>
                        </div>
                    </Col>
                    <Col md={12} className='mt-4'>
                        <h4 className='mb-4'>GoDMARC Service Advantages</h4>
                        <div className=''>
                            <ul className='list-unstyled'>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Integration less than 5 Minutes</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Indepth Reporting and analytics</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Unlimited DMARC Reports Processing</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Bundled with Simulation Attacks and Typo Squatting Domain Alerts</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>Multiple plans to choose depending upon your business needs</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>24/7 Multi-Channel Support via Chat, Phone, Email, & Tickets</span>
                                </li>
                                <li className='list-item'>
                                    <span className='list-icon'>
                                        <FontAwesomeIcon icon={faCheck} className='green' />
                                    </span>
                                    <span className='list-text'>At GoDMARC, we provide incomparable solution to different business needs under a single roof</span>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default GoDmarc
