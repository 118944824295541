import React from 'react';

const NoPage = () => {
  return (
    <div className="text-center">
    	<h1>404</h1>
    	<p>page not found.</p>
    </div>
  )
}

export default NoPage;