import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function SecuritySolutions() {
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='un-heading'>
                            <h2 className='text-center'>Security <span>Solutions</span></h2>
                            <p>Security solutions encompass a broad range of strategies, technologies, and practices designed to protect information, systems, and assets from various threats. Focuses on protecting digital assets from cyber threats.</p>
                        </div>
                    </Col>
                    <Col md={12} className='mt-5'>
                        <div className='un-heading'>
                            <h5 className='mb-4'>Sophos</h5>
                            <p>Sophos provides broad range of security solutions designed to protect businesses and organizations from various digital threats. Its offerings include endpoint protection, which safeguards individual devices like computers and smartphones from malware and ransomware; network security solutions such as firewalls and intrusion prevention systems (IPS); and cloud security products that protect data and applications in cloud environments. Additionally, Sophos provides email security to guard against phishing and spam, managed security services for threat monitoring and incident response, and encryption solutions to secure data both in transit and at rest. Renowned for its user-friendly interfaces and integration capabilities, Sophos is a popular choice for comprehensive security needs.</p>
                        </div>
                    </Col>
                    <Col md={12} className='mt-5'>
                        <div className='un-heading'>
                            <h5 className='mb-4'>Kaspersky</h5>
                            <p>Kaspersky is known for its advanced solutions in protecting against a wide range of digital threats. Kaspersky's product lineup includes antivirus software, endpoint protection, and internet security solutions designed to safeguard computers, mobile devices, and networks from malware, ransomware, phishing attacks, and other cyber threats. The company also offers specialized products for business environments, including network security, cloud security, and threat intelligence services. Kaspersky's reputation for high-quality detection and response capabilities, combined with its research into emerging cyber threats, has made it a leading player in the cybersecurity industry. Despite facing some geopolitical challenges, Kaspersky continues to be a major force in providing robust security solutions worldwide.</p>
                        </div>
                    </Col>
                    <Col md={12} className='mt-5'>
                        <div className='un-heading'>
                            <h5 className='mb-4'>GoDMARC</h5>
                            <p>DMARC (Domain-based Message Authentication, Reporting & Conformance) is an email security protocol designed to prevent email spoofing and phishing. It builds on existing technologies like SPF (Sender Policy Framework) and DKIM (DomainKeys Identified Mail) by adding a policy layer and reporting mechanism. Domain owners publish a DMARC policy in their DNS records, specifying how to handle emails that fail authentication checks—such as quarantining or rejecting them.</p>
                            <p>Explore the incredible capabilities of GoDMARC, an advanced cloud-based tool made to simplify your DMARC setup. Tailored precisely to suit business needs, GoDMARC gives you a complete view of your email security with its easy-to-use dashboard.</p>
                            <p>GoDMARC provides comprehensive reporting, giving you detailed insights into email authentication status and domain usage. This transparency helps you understand how your domain is being utilized and ensures that only legitimate emails are sent on your behalf.</p>
                            <p>With GoDMARC, you can easily confirm the authenticity of your emails with our advanced DMARC software. Gain a deeper understanding of email authentication tools such as SPF (Sender Policy Framework), DKIM (DomainKeys Identified Mail), and BIMI (Brand Indicators for Message Identification). Our solution provides clear visibility into potential threats, including details on who is attempting to misuse your domain, from which IPs, and how frequently these attempts occur. Simplify the complexities of email security and enhance your email marketing efforts by monitoring which IPs are sending your emails and their reputation—whether good, poor, or neutral. Additionally, GoDMARC offers Dynamic SPF capabilities to handle SPF netblock limitations, ensuring that you stay within the 10 DNS lookup limit and maintain optimal email deliverability. With GoDMARC, you can protect your domain, boost deliverability, and streamline your email security strategy.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default SecuritySolutions