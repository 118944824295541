import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebookF, faGooglePlusG, faSquareInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import { Col } from 'react-bootstrap';

const Footer = () => {
	const SocialMedia = () => {
		return (
			<>
			<h5>Our Social Networks</h5>
		    {/* <p>Lorem ipsum dolor sit amet, consectetur, adipisicing elit. Quisquam consequuntur quae odio quibusdam iure fuga voluptates, et molestiae porro in sint hic, doloremque dolor itaque beatae, dolorem ab consequatur rem.</p> */}
			<div className="social-links mt-3">
				<a href="https://www.linkedin.com/in/umbrellanetworks/" className="linkedin" target='_blank'>
					<FontAwesomeIcon icon={faLinkedinIn} />
                </a>
				<a href="#" className="facebook">
					<FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="#" className="twitter">
					<FontAwesomeIcon icon={faTwitter} />
                </a>
                {/* <a href="#" className="instagram">
					<FontAwesomeIcon icon={faSquareInstagram} />
                </a>  */}
                {/* <a href="#" className="google-plus">
					<FontAwesomeIcon icon={faGooglePlusG} />
                </a>  */}
            </div>
            </>
		)
	}
	const Copyright = () => {
		return (
			<div className="footer-bottom">
		        <div className="container">
		            <div className="row">
						<Col md={12}>
		                <div className="copyright"> © Copyright 2024 <strong><span>Umbrella Networks</span></strong>. All Rights Reserved.</div>
						</Col>
						{/* <Col md={6} className='footer-links'>
							<div className="social-links">
								<a href="https://www.linkedin.com/in/umbrellanetworks/" className="linkedin" target='_blank'>
									<FontAwesomeIcon icon={faLinkedinIn} />
                				</a>
							</div>
						</Col> */}
		            </div>
		        </div>
		    </div>
		)
	}
    return (
        <footer id="footer">
		    <div className="footer-top">
		        <div className="container">
		            <div className="row">
		                <div className="col-lg-3 col-md-6 footer-contact">
		                    <h5>Contact Us</h5>
		                    <p>90/104 A, Iftikharabad,
								<br /> Latush Road,
		                        <br /> Kanpur - 208001,
								<br /> Uttar Pradesh, India
		                        <br /><br /> <strong>Email:</strong> info@umbrellanetworks.in
		                        <br /> <strong>Phone:</strong> +91 7011797800<br />
		                    </p>
		                </div>
		                <div className="col-lg-3 col-md-6 footer-links">
		                    <h5>Useful Links</h5>
		                    <ul>
		                        <li>
		                            <i className="fa fa-solid fa-chevron-right"></i>
		                            <Link to="/">Home</Link>
		                        </li>
		                        <li>
		                            <i className="fa fa-solid fa-chevron-right"></i> 
		                            <Link to="/about">About Us</Link>
		                        </li>
		                        <li>
		                            <i className="fa fa-solid fa-chevron-right"></i> 
		                            <Link to="/services">Services</Link>
		                        </li>
		                        <li>
		                            <i className="fa fa-solid fa-chevron-right"></i> 
		                            <Link to="/terms">Terms of service</Link>
		                        </li>
		                        <li>
		                            <i className="fa fa-solid fa-chevron-right"></i> 
		                            <Link to="/privacy">Privacy policy</Link>
		                        </li>
		                    </ul>
		                </div>
		                <div className="col-lg-3 col-md-6 footer-links">
		                    <h5>Services</h5>
		                    <ul>
		                        <li>
		                            <i className="fa fa-solid fa-chevron-right"></i> 
		                            <Link to="/webdesign">Web Design</Link>
		                        </li>
		                        <li>
		                            <i className="fa fa-solid fa-chevron-right"></i> 
		                            <Link to="/webdevelopment">Web Development</Link>
		                        </li>
		                        <li>
		                            <i className="fa fa-solid fa-chevron-right"></i> 
		                            <Link to="/productmanagement">Product Management</Link>
		                        </li>
		                        <li>
		                            <i className="fa fa-solid fa-chevron-right"></i> 
		                            <Link to="/marketing">Marketing</Link>
		                        </li>
		                        <li>
		                            <i className="fa fa-solid fa-chevron-right"></i> 
		                            <Link to="/graphicdesign">Graphic Design</Link>
		                        </li>
		                    </ul>
		                </div>
		                <div className="col-lg-3 col-md-6 footer-links">
		                    <SocialMedia />
		                </div>
		            </div>
		        </div>
		    </div>
		    <Copyright />
		</footer>
    )
}

export default Footer;