import React, { useState } from 'react';
import { Link } from "react-router-dom";
import '../css/menu-style.css';

const Menu = () => {
	const [activeMenuItem, setActiveMenuItem] = useState('home');

	const handleMenuItemClick = (menuItem) => {
		setActiveMenuItem(menuItem);
	};

	return (
		<div className="menu-wrap">
			<div id="cssmenu">
				<ul>
					<li className={"menu-item " + (activeMenuItem === 'home' ? 'active' : '')}>
						<Link to="/" onClick={() => handleMenuItemClick('home')}>Home</Link>
					</li>
					<li className={"menu-item " + (activeMenuItem === 'about' ? 'active' : '')}>
						<Link to="/about" onClick={() => handleMenuItemClick('about')}>About Us</Link>
					</li>
					<li className={"menu-item " + (activeMenuItem === 'services' ? 'active' : activeMenuItem === 'cloud-sevices' ? 'active' : activeMenuItem === 'cloud-computing' ? 'active' : activeMenuItem === 'cloud-migration' ? 'active' : activeMenuItem === 'active-directory-exchange-services' ? 'active' : activeMenuItem === 'training' ? 'active' : '')}>
						{/* <Link to="/" onClick={() => handleMenuItemClick('services')}>Services</Link> */}
						<span>Services</span>
						<ul className='submenu'>
							<li>
								<Link to="/services/godmarc" onClick={() => handleMenuItemClick('godmarc')}>GoDMARC</Link>
							</li>
							<li>
								<Link to="/services/neumails" onClick={() => handleMenuItemClick('neumails')}>neuMails</Link>
							</li>
							{/* <li>
								<Link to="/services/cloud-sevices" onClick={() => handleMenuItemClick('cloud-sevices')}>Cloud Sevices</Link>
							</li>
							<li>
								<Link to="/services/cloud-computing" onClick={() => handleMenuItemClick('cloud-computing')}>Cloud Computing</Link>
							</li>
							<li>
								<Link to="/services/cloud-migration" onClick={() => handleMenuItemClick('cloud-migration')}>Cloud Migration</Link>
							</li>
							<li>
								<Link to="/services/active-directory-exchange-services" onClick={() => handleMenuItemClick('active-directory-exchange-services')}>Active Directory & Exchange Services</Link>
							</li>
							<li>
								<Link to="/services/training" onClick={() => handleMenuItemClick('training')}>Training</Link>
							</li> */}
						</ul>
					</li>
					<li className={"menu-item " + (activeMenuItem === 'solutions' ? 'active' : '')}>
						<span>Solutions</span>
						<ul className='submenu'>
							{/* <li>
								<Link to="/solutions/microsoft-azure" onClick={() => handleMenuItemClick('microsoft-azure')}>Microsoft Azure</Link>
							</li> */}
							{/* <li>
								<Link to="/solutions/aws" onClick={() => handleMenuItemClick('aws')}>AWS</Link>
							</li> */}
							<li>
								<Link to="/solutions/microsoft-365" onClick={() => handleMenuItemClick('microsoft-365')}>Microsoft 365</Link>
							</li>
							<li>
								<Link to="/solutions/security-solutions" onClick={() => handleMenuItemClick('security-solutions')}>Security Solutions</Link>
							</li>
							{/* <li>
								<Link to="/solutions/mobile-device-management" onClick={() => handleMenuItemClick('mobile-device-management')}>Mobile Device Management</Link>
							</li> */}
							<li>
								<Link to="/solutions/microsoft-licensing" onClick={() => handleMenuItemClick('microsoft-licensing')}>Microsoft Licensing</Link>
							</li>
							{/* <li>
								<Link to="/solutions/adobe-licensing" onClick={() => handleMenuItemClick('adobe-licensing')}>Adobe Licensing</Link>
							</li> */}
							{/* <li>
								<Link to="/solutions/vmware" onClick={() => handleMenuItemClick('vmware')}>VMWare</Link>
							</li> */}
						</ul>
					</li>
					<li className={"menu-item " + (activeMenuItem === 'contact' ? 'active' : '')}>
						<Link to="/contact" onClick={() => handleMenuItemClick('contact')}>Contact Us</Link>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default Menu;