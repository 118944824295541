import React from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import Home from '../pages/Home';
import About from '../pages/About';
import Services from '../pages/Services';
import Contact from '../pages/Contact';
import NoPage from "../pages/NoPage";
import Solutions from '../pages/Solutions';
import Banner from '../pages/Banner';
import WebDesign from '../pages/WebDesign';
import MicrosoftLicensing from '../pages/MicrosoftLicensing';
import SecuritySolutions from '../pages/SecuritySolutions';
import Microsoft365 from '../pages/Microsoft365';
import GoDmarc from '../pages/GoDmarc';
import NeuMails from '../pages/NeuMails';

const routeTitles = {
    '/': 'An Artificial intelligence enabler'
};

const Main = () => {
    const location = useLocation();
    const currentPath = location.pathname;

    // Get the title based on the current path
    const pageName = routeTitles[currentPath] || '';
    return (
        <>
        <Banner pageName={pageName} />
        <main id="main">
            <Routes>
                <Route path='/' exact element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/services' element={<Services />} />
                <Route path='/services/web-design' element={<WebDesign />} />
                <Route path='/services/godmarc' element={<GoDmarc />} />
                <Route path='/services/neumails' element={<NeuMails />} />
                <Route path='/solutions' element={<Solutions />} />
                <Route path='/solutions/microsoft-365' element={<Microsoft365 />} />
                <Route path='/solutions/security-solutions' element={<SecuritySolutions />} />
                <Route path='/solutions/microsoft-licensing' element={<MicrosoftLicensing />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='*' element={<NoPage />} />
            </Routes>
        </main>
        </>
    )
}

export default Main;