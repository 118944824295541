import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

function MicrosoftLicensing() {
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='un-heading'>
                            <h2 className='text-center'>Microsoft <span>Licensing</span></h2>
                            <p>Microsoft Licensing refers to the various ways in which Microsoft software and services can be legally acquired and used by individuals, businesses, and organizations. It encompasses a range of options tailored to different needs and sizes of organizations.</p>
                            <p><b>Microsoft Open License/Open Value:</b> For small to medium-sized businesses, offering a range of products with options for upfront or annual payments.</p>
                            <p><b>Subscription Licensing:</b> This model involves paying a recurring fee to use software or services. Common examples include Microsoft 365 (formerly Office 365), which provides access to applications like Word, Excel, and Teams, along with cloud services such as OneDrive.</p>
                            <p><b>OEM Licensing:</b> Original Equipment Manufacturer (OEM) licenses are sold with new hardware, such as PCs and servers. These licenses are tied to the hardware they are sold with and are generally less expensive but come with specific terms and limitations.</p>
                            <p><b>Educational Licensing:</b> Special pricing and licensing options are available for educational institutions, providing access to software and services at discounted rates to support teaching and learning.</p>
                            <p>Each licensing model has its own terms, conditions, and benefits, making it essential for organizations to choose the one that best aligns with their size, needs, and budget.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default MicrosoftLicensing