import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Banner from './Banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', { name, email, message });

    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <h2>Contact Us</h2>
            <p>Get in touch and we’ll get back to you as soon as we can. We look forward to hearing from you!</p>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className='p-4 bx-sh br-10 bg-white'>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="formMessage">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter your message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required
                    maxLength={150}
                    style={{ height: '150px' }}
                  />
                </Form.Group>
                <br />
                <Button variant="none" type="submit" className='read-more'>
                  Send message
                </Button>
              </Form>
            </div>
          </Col>
          <Col md={{ span: 4, offset: 2 }}>
            <h5>Get in Touch</h5>
            <p>90/104 A, Iftikharabad,
              <br /> Latouche Road,
              <br /> Kanpur - 208001,
              <br /> Uttar Pradesh, India
              <br /><br /><strong>Email:</strong> info@umbrellanetworks.in
              <br /><strong>Phone:</strong> +91 7607630252 <br />
            </p>
            <div className='footer-links'>
              <h5>Our Social Networks</h5>
              <div className="social-links">
                <a href="https://www.linkedin.com/in/umbrellanetworks/" className="linkedin" target='_blank'>
                  <FontAwesomeIcon icon={faLinkedinIn} />
                </a>
                <a href="#" className="facebook">
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="#" className="twitter">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Contact;
