import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const About = () => {
    return (
        <>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='un-heading text-center'>
                            <h2>About <span>Us</span></h2>
                            <p className='intro-paragraph'>At Umbrella Networks, we are committed to providing a comprehensive suite of IT solutions designed to meet your diverse business needs. Our offerings include software licenses, innovative solutions, and hardware—all tailored to enhance your technology environment and drive your business forward.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className='mt-5'>
                        <div className='un-heading text-center'>
                            <h2>Our <span>Expertise</span></h2>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='home-box text-center'>
                            <h5>Software Licenses</h5>
                            <p>We streamline the complexities of software procurement and license management, ensuring you receive optimal value and compliance while maximizing your technology investments.</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='home-box text-center'>
                            <h5>Solutions</h5>
                            <p>Our advanced IT solutions are crafted to address your specific challenges and objectives, equipping your organization with the tools needed to excel in today’s competitive landscape.</p>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='home-box text-center'>
                            <h5>Hardware</h5>
                            <p>We offer a range of high-quality hardware solutions that complement our software and solutions, ensuring you have all the essential components to build a robust and effective IT infrastructure.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className='mt-5'>
                        <div className='un-heading text-center'>
                            <h2>Our <span>Commitment</span></h2>
                            <p>At Umbrella Networks, our core philosophy revolves around understanding and addressing your unique needs. We believe that by closely collaborating with you, we can deliver tailored solutions that not only meet but exceed your expectations. Our integrated approach allows us to offer a streamlined experience, helping you manage your IT environment more efficiently and effectively.</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className='mt-5'>
                        <div className='un-heading text-center'>
                            <h2>Why <span>Choose Us?</span></h2>
                            <p>Our team of dedicated professionals is passionate about providing exceptional service and expertise. With Umbrella Networks as your partner, you can expect a seamless experience and unparalleled support designed to help you achieve your business goals.</p>
                            <p>Umbrella Networks look forward to the opportunity to work with you and demonstrate how our solutions can make a significant impact on your organization’s success.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default About;