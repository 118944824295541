import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function Banner(props) {
  return (
    <section id='banner'>
      <Container>
        <Row>
          <Col sm={12}>
            <div className='banner-text'>
              <h1>{props.pageName}</h1>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Banner;